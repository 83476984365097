<template>
    <div v-if="reloadForm == false">
        <div v-if="item">
            <div class="grid">
                <div class="col-12">
                    <BlockUI :blocked="blockedPanel">
                        <Toast />
                        <Error :errors="errors" />
                        <div class="card">
                            <h3>EDIT BEAT</h3>
                            <Message severity="info" :closable="false">
                                <div class="text-xl">
                                    Beat : <b>{{ item.beat_name }}</b> <br>
                                    Salesman : <b>{{ item.master_salesman.salesman_code }} -
                                        {{ item.master_salesman.salesman_name }}</b>
                                </div>
                            </Message>
                            <div class="p-fluid">
                                <div class="formgrid grid">
                                    <div class="field col-12 md:col-6 lg:col-3">
                                        <label>Kabupaten</label>
                                        <Dropdown id="kabupaten_id" dataKey="kabupaten_id" v-model="filters.kabupaten_id"
                                            :loading="loadingDropdownKabupaten" :options="dataDropdownKabupaten"
                                            optionLabel="label" optionValue="kabupaten_id" placeholder="Pilih Kabupaten"
                                            :filter="true" :showClear="true"
                                            @filter="searchDropdownKabupaten($event, 'add')" @change="changeKecamatan()" />
                                    </div>
                                    <div class="field col-12 md:col-6 lg:col-3">
                                        <label>Kecamatan</label>
                                        <Dropdown id="kecamatan_id" dataKey="kecamatan_id" v-model="filters.kecamatan_id"
                                            :loading="loadingDropdownKecamatan" :options="dataDropdownKecamatan"
                                            optionLabel="label" optionValue="kecamatan_id" placeholder="Pilih Kecamatan"
                                            :filter="true" :showClear="true"
                                            @filter="searchDropdownKecamatan($event, 'add')" @change="changeKelurahan()" />
                                    </div>
                                    <div class="field col-12 md:col-6 lg:col-3">
                                        <label>Kelurahan</label>
                                        <Dropdown id="kelurahan_id" dataKey="kelurahan_id" v-model="filters.kelurahan_id"
                                            :loading="loadingDropdownKelurahan" :options="dataDropdownKelurahan"
                                            optionLabel="kelurahan_name" optionValue="kelurahan_id"
                                            placeholder="Pilih Kelurahan" :filter="true" :showClear="true"
                                            @filter="searchDropdownKelurahan($event, 'add')"
                                            @change="changeSpesificKelurahan()" />
                                    </div>
                                </div>
                                <div class="formgrid grid">
                                    <div class="field col-12 md:col-8 lg:col-8">
                                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center mb-2">
                                            <div>
                                                <Button type="button" label="Refresh" icon="pi pi-refresh" class="p-button-info" @click="changeSpesificKelurahan()"/>
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                        <div class="map" id="outlet_map"></div>
                                    </div>
                                    <div class="field col-12 md:col-4 lg:col-4">
                                        <div class="card">
                                            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center mb-2">
                                                <div>
                                                </div>
                                                <div>
                                                    <Button type="button" :label="'Total Outlet '+this.totalOutlet" icon="pi pi-users" class="p-button-warning"/>
                                                </div>
                                            </div>
                                            <DataTable :value="forms.outlet_ids" scrollHeight="600px" :resizableColumns="true" responsiveLayout="scroll" >
                                                <Column bodyStyle="width:7rem">
                                                    <template #body="slotProps">
                                                        <Button icon="pi pi-trash"
                                                            class="p-button-rounded p-button-danger mr-2" style="text-align:center;"
                                                            @click="removeOutlet(slotProps.data)" />
                                                    </template>
                                                </Column>
                                                <Column field="name" header="Nama Outlet">
                                                    <template #body="slotProps">
                                                        {{ slotProps.data.name }}
                                                    </template>
                                                </Column>
                                                <Column header="Aksi">
                                                    <template #body="slotProps">
                                                        <span class="p-buttonset">
                                                            <Button label="Edit" class="p-button-primary" @click="editMasterOutlet(slotProps.data)"/>
                                                            <Button label="Deactive" class="p-button-danger" @click="deactiveMasterOutlet(slotProps.data)" />
                                                        </span>
                                                    </template>
                                                </Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Button label="Back" class="p-button-warning mr-2" @click="back" />
                            <Button :loading="loading" label="Simpan" icon="pi pi-save" class="p-button-primary"
                                @click="updateItem" />
                        </div>
                        <EditOutlet @submit="changeItem" :id="outletId" :outlet_data="forms.outlet_ids" ref="edit" />
                        <DeactiveTemp :id="outletId" :name="outletName" ref="deactive" />
                    </BlockUI>
                </div>
            </div>
        </div>
        <div v-else>
            <h3 class="text-center">Data Tidak Ditemukan</h3>
        </div>
    </div>
    <div v-else>
        <div class="card">
            <div class="grid formgrid">
                <div class="field col-12 md:col-12">
                    <Skeleton class="mb-2"></Skeleton>
                    <Skeleton width="10rem" class="mb-2"></Skeleton>
                    <Skeleton width="5rem" class="mb-2"></Skeleton>
                    <Skeleton height="2rem" class="mb-2"></Skeleton>
                    <Skeleton width="100%" height="10rem"></Skeleton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import EditOutlet from './EditOutletTemp.vue';
import DeactiveTemp from './DeactiveTemp.vue';
import Error from '../../Error.vue';

// eslint-disable-next-line no-undef
var markers = L.markerClusterGroup();

export default {
    components: {
        'EditOutlet': EditOutlet,
        'DeactiveTemp': DeactiveTemp,
        'Error': Error,
    },
    data() {
        return {
            // loading
            blockedPanel: false,
            reloadForm: false,
            loading: false,
            loadingDropdownKabupaten: false,
            loadingDropdownKecamatan: false,
            loadingDropdownKelurahan: false,

            // dataDropdown
            dataDropdownKabupaten: null,
            dataDropdownKecamatan: null,
            dataDropdownKelurahan: null,

            // filter
            filters: {
                provinsi_id: null,
                kabupaten_id: null,
                subdist_id: null,
                kecamatan_id: null,
                kelurahan_id: null,
                salesman_id: null,
            },

            // maps
            map: null,
            zoom: 5,
            lat: -3.375126556326247,
            lng: 118.84902874210889,
            location_list: [],
            marker_list: [],

            // outlet detail
            outletId: null,
            outletName: null,

            item: null,

            forms: {
                outlet_ids: []
            }
        }
    },
    created() {
        
        this.location_list= [],
        this.marker_list= [],
        this.getItemById(this.$route.params.id);
    },
    mounted() {
        this.$store.commit('setErrors', {});
        // this.searchDropdownKecamatan('');

        window.getOutletFromId = this.getOutletFromId.bind(this);
        window.removeOutletFromIcon = this.removeOutletFromIcon.bind(this);
    },
    computed: {
        ...mapGetters(['errors']),
        totalOutlet(){ 
            return this.forms.outlet_ids.length; 
        }
    },
    methods: {
        convertMultiOutlet(data) {
            if (data) {
                return data.map((item) => {
                    return item.id;
                });
            }
        },
        convertDataOutlet(data) {
            if (data) {
                return data.map((item) => {
                    return {
                        id: item.outlet_id,
                        name: item.master_outlet?.outlet_id ? item.master_outlet.outlet_name : item.master_outlet_deactive.outlet_name,
                    };
                }).sort((a, b) => {
                    const nameA = a.name.trim().toLowerCase();
                    const nameB = b.name.trim().toLowerCase();
                    
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });
            }
        },
        convertMultiIcon(data, status) {
            var iconUrl = '';
            if (data.outlet_type_id == 1 && status) {
                iconUrl = "/marker/marker-type-outlet-dry-beat.png";
            } else if (data.outlet_type_id == 1 && !status) {
                iconUrl = "/marker/marker-type-outlet-dry.png";
            }

            if (data.outlet_type_id == 2 && status) {
                iconUrl = "/marker/marker-type-outlet-lainnya-beat.png";
            } else if (data.outlet_type_id == 2 && !status) {
                iconUrl = "/marker/marker-type-outlet-lainnya.png";
            }

            return iconUrl;
        },
        // EDIT
        editMasterOutlet(item) {
            this.outletId = item.id;

            this.$refs.edit.editItemDialog = true;
        },
        // DEACTIVE
        deactiveMasterOutlet(item) {
            this.outletId = item.id;
            this.outletName = item.name;

            this.$refs.deactive.deactiveItemDialog = true;
        },
        // CHANGE
        changeItem(data) {
            console.log(data);
            this.forms.outlet_ids = data;
        },
        //DROPDOWN
        searchDropdownKabupaten(event, purpose, valueEdit) {
            setTimeout(() => {
                // if (this.filters.provinsi_id) {

                    if (valueEdit) {
                        this.$refs.dkab.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownKabupaten = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/kabupaten',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            // "provinsi_id": this.filters.provinsi_id,
                            "salesman_id": this.filters.salesman_id,
                        }
                    })
                        .then(res => {

                            if (purpose == "add") {
                                this.dataDropdownKabupaten = res.data.data;
                                this.loadingDropdownKabupaten = false;
                            } else if (purpose == null) {
                                this.dataDropdownKabupaten = res.data.data;
                            }
                            
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                // }
            }, 250);
        },
        changeKecamatan() {
            this.filters.kecamatan_id = null;
            this.filters.kelurahan_id = null;
            this.dataDropdownKecamatan = null;
            this.dataDropdownKelurahan = null;
            this.searchDropdownKecamatan('');
            this.searchDropdownKelurahan('');
            this.changeSpesificKabupaten();
        },
        searchDropdownKecamatan(event, purpose, valueEdit) {
            setTimeout(() => {
                if (valueEdit) {
                    this.$refs.dkecamatan.filterValue = valueEdit;
                }

                if (purpose == "add") {
                    this.loadingDropdownKecamatan = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/kecamatanv2',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                        "kabupaten_id": this.filters.kabupaten_id,
                        "subdist_id": this.filters.subdist_id,
                    }
                })
                    .then(res => {

                        if (purpose == "add") {
                            this.dataDropdownKecamatan = res.data.data;
                            this.loadingDropdownKecamatan = false;
                        } else if (purpose == null) {
                            this.dataDropdownKecamatan = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        changeKelurahan() {
            this.filters.kelurahan_id = null;
            this.dataDropdownKelurahan = null;
            this.searchDropdownKelurahan('');
            this.changeSpesificKecamatan();
        },
        searchDropdownKelurahan(event, purpose, valueEdit) {
            setTimeout(() => {
                if (this.filters.kecamatan_id) {

                    if (valueEdit) {
                        this.$refs.dkelurahan.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownKelurahan = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/kelurahan',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "kecamatan_id": this.filters.kecamatan_id,
                        }
                    })
                        .then(res => {

                            if (purpose == "add") {
                                this.dataDropdownKelurahan = res.data.data;
                                this.loadingDropdownKelurahan = false;
                            } else if (purpose == null) {
                                this.dataDropdownKelurahan = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        changeSpesificKabupaten() {

            this.blockedPanel= true;
            
            setTimeout(() => {

                this.marker_list = [];

                markers.clearLayers();
                // eslint-disable-next-line no-unused-vars
                this.location_list.map(function (value, key) {

                    if (value.outlet_latitude != null && value.outlet_longitude != null && (this.filters.kabupaten_id == null || value.master_kelurahan.master_kecamatan.kabupaten_id == this.filters.kabupaten_id)) {

                        var is_exist_outlet = false;

                        for (let index = 0; index < this.forms.outlet_ids.length; index++) {
                            const element = this.forms.outlet_ids[index];
                            if (element.id == value.outlet_id) {
                                is_exist_outlet = true;
                            }
                        }

                        var iconUrl = this.convertMultiIcon(value, is_exist_outlet);


                        var htmlcontent = "<b>Kode Outlet</b> : " + value.outlet_code + "</b><br/>";
                        htmlcontent += "<b>Nama Outlet</b> : " + value.outlet_name + "<br/>";

                        if (is_exist_outlet) {
                            htmlcontent += `<button class="btn-outlet-${value.outlet_id}" onclick='event.preventDefault();removeOutletFromIcon(${value.outlet_id});' >Hapus</button>`
                        } else {
                            htmlcontent += `<button class="btn-outlet-${value.outlet_id}" onclick='event.preventDefault();getOutletFromId(${value.outlet_id});' >Tambah</button>`
                        }

                        // eslint-disable-next-line no-undef
                        var icon = L.icon({
                            iconUrl: iconUrl,
                            // shadowUrl: 'leaf-shadow.png',
                            className: `outlet-marker-${value.outlet_id}`,
                            outlet_id: value.outlet_id,
                            outlet_code: value.outlet_code,
                            outlet_name: value.outlet_name,
                            outlet_type_id: value.outlet_type_id,
                            iconSize: [21, 36], // size of the icon
                            // shadowSize:   [50, 64], // size of the shadow
                            iconAnchor: [22, 40], // point of the icon which will correspond to marker's location
                            shadowAnchor: [4, 62], // the same for the shadow
                            popupAnchor: [-10, -46], // point from which the popup should open relative to the iconAnchor
                        })


                        // eslint-disable-next-line no-undef
                        var marker = L.marker([value.outlet_latitude, value.outlet_longitude],
                            {
                                icon: icon,
                            });

                        // eslint-disable-next-line no-unused-vars
                        var popup = marker.bindPopup(htmlcontent);
                        markers.addLayer(marker);


                    }
                }, (this));

                this.map.addLayer(markers);

                var markers_result = markers.getLayers().filter(function (layer) {
                    // eslint-disable-next-line no-undef
                    return layer instanceof L.Marker;
                });

                this.marker_list = markers_result;

                this.blockedPanel = false;
                
            }, 3000);


        },
        changeSpesificKecamatan() {

            //Catatan jika misal kelurahan id null maka kembali ke kecamatan
            if (this.filters.kecamatan_id == null) {
                this.changeSpesificKabupaten();
                return
            }

            this.blockedPanel= true;
            
            setTimeout(() => {

                this.marker_list = [];

                markers.clearLayers();
                // eslint-disable-next-line no-unused-vars
                this.location_list.map(function (value, key) {

                    if (value.outlet_latitude != null && value.outlet_longitude != null && (this.filters.kecamatan_id == null || value.master_kelurahan.kecamatan_id == this.filters.kecamatan_id)) {

                        var is_exist_outlet = false;

                        for (let index = 0; index < this.forms.outlet_ids.length; index++) {
                            const element = this.forms.outlet_ids[index];
                            if (element.id == value.outlet_id) {
                                is_exist_outlet = true;
                            }
                        }

                        var iconUrl = this.convertMultiIcon(value, is_exist_outlet);


                        var htmlcontent = "<b>Kode Outlet</b> : " + value.outlet_code + "</b><br/>";
                        htmlcontent += "<b>Nama Outlet</b> : " + value.outlet_name + "<br/>";

                        if (is_exist_outlet) {
                            htmlcontent += `<button class="btn-outlet-${value.outlet_id}" onclick='event.preventDefault();removeOutletFromIcon(${value.outlet_id});' >Hapus</button>`
                        } else {
                            htmlcontent += `<button class="btn-outlet-${value.outlet_id}" onclick='event.preventDefault();getOutletFromId(${value.outlet_id});' >Tambah</button>`
                        }

                        // eslint-disable-next-line no-undef
                        var icon = L.icon({
                            iconUrl: iconUrl,
                            // shadowUrl: 'leaf-shadow.png',
                            className: `outlet-marker-${value.outlet_id}`,
                            outlet_id: value.outlet_id,
                            outlet_code: value.outlet_code,
                            outlet_name: value.outlet_name,
                            outlet_type_id: value.outlet_type_id,
                            iconSize: [21, 36], // size of the icon
                            // shadowSize:   [50, 64], // size of the shadow
                            iconAnchor: [22, 40], // point of the icon which will correspond to marker's location
                            shadowAnchor: [4, 62], // the same for the shadow
                            popupAnchor: [-10, -46], // point from which the popup should open relative to the iconAnchor
                        })


                        // eslint-disable-next-line no-undef
                        var marker = L.marker([value.outlet_latitude, value.outlet_longitude],
                            {
                                icon: icon,
                            });

                        // eslint-disable-next-line no-unused-vars
                        var popup = marker.bindPopup(htmlcontent);
                        markers.addLayer(marker);


                    }
                }, (this));

                this.map.addLayer(markers);

                var markers_result = markers.getLayers().filter(function (layer) {
                    // eslint-disable-next-line no-undef
                    return layer instanceof L.Marker;
                });

                this.marker_list = markers_result;

                this.blockedPanel = false;
                
            }, 3000);


        },
        changeSpesificKelurahan() {

            //Catatan jika misal kelurahan id null maka kembali ke kecamatan
            if (this.filters.kelurahan_id == null) {
                this.changeSpesificKecamatan();
                return
            }

            this.blockedPanel= true;
            
            setTimeout(() => {

                this.marker_list = [];

                markers.clearLayers();
                // eslint-disable-next-line no-unused-vars
                this.location_list.map(function (value, key) {

                    if (value.outlet_latitude != null && value.outlet_longitude != null && (this.filters.kelurahan_id == null || value.kelurahan_id == this.filters.kelurahan_id)) {


                        var is_exist_outlet = false;
                        for (let index = 0; index < this.forms.outlet_ids.length; index++) {
                            const element = this.forms.outlet_ids[index];
                            if (element.id == value.outlet_id) {
                                is_exist_outlet = true;
                            }
                        }
                        var iconUrl = this.convertMultiIcon(value, is_exist_outlet);

                        var htmlcontent = "<b>Kode Outlet</b> : " + value.outlet_code + "</b><br/>";
                        htmlcontent += "<b>Nama Outlet</b> : " + value.outlet_name + "<br/>";

                        if (is_exist_outlet) {
                            htmlcontent += `<button class="btn-outlet-${value.outlet_id}" onclick='event.preventDefault();removeOutletFromIcon(${value.outlet_id});' >Hapus</button>`
                        } else {
                            htmlcontent += `<button class="btn-outlet-${value.outlet_id}" onclick='event.preventDefault();getOutletFromId(${value.outlet_id});' >Tambah</button>`
                        }

                        // eslint-disable-next-line no-undef
                        var icon = L.icon({
                            iconUrl: iconUrl,
                            // shadowUrl: 'leaf-shadow.png',
                            className: `outlet-marker-${value.outlet_id}`,
                            outlet_id: value.outlet_id,
                            outlet_code: value.outlet_code,
                            outlet_name: value.outlet_name,
                            outlet_type_id: value.outlet_type_id,
                            iconSize: [21, 36], // size of the icon
                            // shadowSize:   [50, 64], // size of the shadow
                            iconAnchor: [22, 40], // point of the icon which will correspond to marker's location
                            shadowAnchor: [4, 62], // the same for the shadow
                            popupAnchor: [-10, -46], // point from which the popup should open relative to the iconAnchor
                        })


                        // eslint-disable-next-line no-undef
                        var marker = L.marker([value.outlet_latitude, value.outlet_longitude],
                            {
                                icon: icon,
                            });

                        // eslint-disable-next-line no-unused-vars
                        var popup = marker.bindPopup(htmlcontent);
                        markers.addLayer(marker);


                    }
                }, (this));

                this.map.addLayer(markers);

                var markers_result = markers.getLayers().filter(function (layer) {
                    // eslint-disable-next-line no-undef
                    return layer instanceof L.Marker;
                });

                this.marker_list = markers_result;

                this.blockedPanel = false;

            }, 3000);

        },
        //MANIPULATE MAPS
        removeOutlet(data) {

            this.forms.outlet_ids = this.forms.outlet_ids.filter(outlet => outlet.id !== data.id);


            for (var i = 0; i < this.marker_list.length; i++) {
                var marker = this.marker_list[i];
                if (marker.options.icon.options.outlet_id == data.id) {

                    var iconUrl = this.convertMultiIcon(marker.options.icon.options, false);

                    // eslint-disable-next-line no-undef
                    var icon = L.icon({
                        iconUrl: iconUrl,
                        // shadowUrl: 'leaf-shadow.png',
                        className: `outlet-marker-${marker.options.icon.options.outlet_id}`,
                        outlet_id: marker.options.icon.options.outlet_id,
                        outlet_code: marker.options.icon.options.outlet_code,
                        outlet_name: marker.options.icon.options.outlet_name,
                        outlet_type_id: marker.options.icon.options.outlet_type_id,
                        iconSize: [21, 36], // size of the icon
                        // shadowSize:   [50, 64], // size of the shadow
                        iconAnchor: [22, 40], // point of the icon which will correspond to marker's location
                        shadowAnchor: [4, 62], // the same for the shadow
                        popupAnchor: [-10, -46], // point from which the popup should open relative to the iconAnchor
                    })
                    var htmlcontent = "<b>Kode Outlet</b> : " + marker.options.icon.options.outlet_code + "</b><br/>";
                    htmlcontent += "<b>Nama Outlet</b> : " + marker.options.icon.options.outlet_name + "<br/>";
                    htmlcontent += `<button class="btn-outlet-${marker.options.icon.options.outlet_id}" onclick='event.preventDefault();getOutletFromId(${marker.options.icon.options.outlet_id});' >Tambah</button>`
                    marker.getPopup().setContent(htmlcontent);
                    marker.setIcon(icon);
                    break;
                }
            }

            return this.forms.outlet_ids;
        },
        addOutlet(data) {
            return this.forms.outlet_ids.push(data);
        },
        removeOutletFromIcon(id) {
            this.forms.outlet_ids = this.forms.outlet_ids.filter(outlet => outlet.id !== id);


            for (var i = 0; i < this.marker_list.length; i++) {
                var marker = this.marker_list[i];
                if (marker.options.icon.options.outlet_id == id) {

                    var iconUrl = this.convertMultiIcon(marker.options.icon.options, false);

                    // eslint-disable-next-line no-undef
                    var icon = L.icon({
                        iconUrl: iconUrl,
                        // shadowUrl: 'leaf-shadow.png',
                        className: `outlet-marker-${marker.options.icon.options.outlet_id}`,
                        outlet_id: marker.options.icon.options.outlet_id,
                        outlet_code: marker.options.icon.options.outlet_code,
                        outlet_name: marker.options.icon.options.outlet_name,
                        outlet_type_id: marker.options.icon.options.outlet_type_id,
                        iconSize: [21, 36], // size of the icon
                        // shadowSize:   [50, 64], // size of the shadow
                        iconAnchor: [22, 40], // point of the icon which will correspond to marker's location
                        shadowAnchor: [4, 62], // the same for the shadow
                        popupAnchor: [-10, -46], // point from which the popup should open relative to the iconAnchor
                    })

                    var htmlcontent = "<b>Kode Outlet</b> : " + marker.options.icon.options.outlet_code + "</b><br/>";
                    htmlcontent += "<b>Nama Outlet</b> : " + marker.options.icon.options.outlet_name + "<br/>";
                    htmlcontent += `<button class="btn-outlet-${marker.options.icon.options.outlet_id}" onclick='event.preventDefault();getOutletFromId(${marker.options.icon.options.outlet_id});' >Tambah</button>`
                    marker.getPopup().setContent(htmlcontent);
                    marker.setIcon(icon);
                    break;
                }
            }

            return this.forms.outlet_ids;
        },
        getOutletFromId(id) {


            var outlet_name = "";

            for (var i = 0; i < this.marker_list.length; i++) {
                var marker = this.marker_list[i];
                if (marker.options.icon.options.outlet_id == id) {
                    outlet_name = marker.options.icon.options.outlet_name;

                    var iconUrl = this.convertMultiIcon(marker.options.icon.options, true);

                    // eslint-disable-next-line no-undef
                    var icon = L.icon({
                        iconUrl: iconUrl,
                        // shadowUrl: 'leaf-shadow.png',
                        className: `outlet-marker-${marker.options.icon.options.outlet_id}`,
                        outlet_id: marker.options.icon.options.outlet_id,
                        outlet_code: marker.options.icon.options.outlet_code,
                        outlet_name: marker.options.icon.options.outlet_name,
                        outlet_type_id: marker.options.icon.options.outlet_type_id,
                        iconSize: [21, 36], // size of the icon
                        // shadowSize:   [50, 64], // size of the shadow
                        iconAnchor: [22, 40], // point of the icon which will correspond to marker's location
                        shadowAnchor: [4, 62], // the same for the shadow
                        popupAnchor: [-10, -46], // point from which the popup should open relative to the iconAnchor
                    })

                    var htmlcontent = "<b>Kode Outlet</b> : " + marker.options.icon.options.outlet_code + "</b><br/>";
                    htmlcontent += "<b>Nama Outlet</b> : " + marker.options.icon.options.outlet_name + "<br/>";
                    htmlcontent += `<button class="btn-outlet-${marker.options.icon.options.outlet_id}" onclick='event.preventDefault();removeOutletFromIcon(${marker.options.icon.options.outlet_id});' >Hapus</button>`
                    marker.getPopup().setContent(htmlcontent);
                    marker.setIcon(icon);
                    break;
                }
            }

            let data = {
                "id": id,
                "name": outlet_name,
            };

            this.addOutlet(data)
        },
        // MAPS
        initMap() {
            setTimeout(() => {
                // eslint-disable-next-line no-undef
                this.map = L.map('outlet_map', {
                    attributionControl: false,
                    doubleClickZoom: true,
                    zoomControl: true,
                    zoom: 1,
                    zoomAnimation: false,
                    fadeAnimation: true,
                    markerZoomAnimation: true,
                    trackResize: false
                }).setView([this.lat, this.lng], this.zoom);

                // eslint-disable-next-line no-undef
                L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
                    attribution: '© OpenStreetMap'
                }).addTo(this.map);
            }, 250);
        },
        showLegend() {
            setTimeout(() => {
                // eslint-disable-next-line no-undef
                var legend = L.control({ position: "bottomleft" });

                legend.onAdd = function () {

                    // eslint-disable-next-line no-undef
                    var div = L.DomUtil.create("div", "legend");
                    div.innerHTML += "<h4>Keterangan</h4>";
                    div.innerHTML += '<i class="icon" style="background-image: url(/marker/marker-type-outlet-dry.png);background-repeat: no-repeat;"></i><span>Dry</span><br>';
                    div.innerHTML += '<i class="icon" style="background-image: url(/marker/marker-type-outlet-lainnya.png);background-repeat: no-repeat;"></i><span>Wet</span><br>';
                    div.innerHTML += '<i style="background: red"></i><span>Sudah Punya Beat</span><br>';
                    div.innerHTML += '<i style="background: black"></i><span>Belum Punya Beat</span><br>';

                    return div;
                };

                legend.addTo(this.map);
            }, 250);
        },
        showMarker() {
            markers.clearLayers();

            this.blockedPanel = true;

            this.location_list = [];

            this.marker_list = [];

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/master-beat/maps-outlet',
                params: {
                    // "subdist_id": this.filters.subdist_id,
                    // "kabupaten_id": this.filters.kabupaten_id,
                    // "provinsi_id": this.filters.provinsi_id,
                    "salesman_id": this.filters.salesman_id,
                    "is_new_survey": this.item.is_new_survey,
                    "beat_id": this.item.beat_id,
                }
            })
                .then(res => {
                    this.location_list = res.data.data;

                    // eslint-disable-next-line no-unused-vars
                    this.location_list.map(function (value, key) {
                        if (value.outlet_latitude != null && value.outlet_longitude != null) {



                            var is_exist_outlet = false;

                            for (let index = 0; index < this.forms.outlet_ids.length; index++) {
                                const element = this.forms.outlet_ids[index];
                                if (element.id == value.outlet_id) {
                                    is_exist_outlet = true;
                                }
                            }

                            var iconUrl = this.convertMultiIcon(value, is_exist_outlet);

                            var htmlcontent = "<b>Kode Outlet</b> : " + value.outlet_code + "</b><br/>";
                            htmlcontent += "<b>Nama Outlet</b> : " + value.outlet_name + "<br/>";

                            if (is_exist_outlet) {
                                htmlcontent += `<button class="btn-outlet-${value.outlet_id}" onclick='event.preventDefault();removeOutletFromIcon(${value.outlet_id});' >Hapus</button>`
                            } else {
                                htmlcontent += `<button class="btn-outlet-${value.outlet_id}" onclick='event.preventDefault();getOutletFromId(${value.outlet_id});' >Tambah</button>`
                            }

                            // eslint-disable-next-line no-undef
                            var icon = L.icon({
                                iconUrl: iconUrl,
                                // shadowUrl: 'leaf-shadow.png',
                                className: `outlet-marker-${value.outlet_id}`,
                                outlet_id: value.outlet_id,
                                outlet_code: value.outlet_code,
                                outlet_name: value.outlet_name,
                                outlet_type_id: value.outlet_type_id,
                                iconSize: [21, 36], // size of the icon
                                // shadowSize:   [50, 64], // size of the shadow
                                iconAnchor: [22, 40], // point of the icon which will correspond to marker's location
                                shadowAnchor: [4, 62], // the same for the shadow
                                popupAnchor: [-10, -46], // point from which the popup should open relative to the iconAnchor
                            })


                            // eslint-disable-next-line no-undef
                            var marker = L.marker([value.outlet_latitude, value.outlet_longitude],
                                {
                                    icon: icon,
                                });


                            // eslint-disable-next-line no-unused-vars
                            var popup = marker.bindPopup(htmlcontent);
                            markers.addLayer(marker);
                        }
                    }, (this));

                    setTimeout(() => {
                        this.map.addLayer(markers);

                        var markers_result = markers.getLayers().filter(function (layer) {
                            // eslint-disable-next-line no-undef
                            return layer instanceof L.Marker;
                        });

                        this.marker_list = markers_result;
                        
                        this.blockedPanel = false;
                    }, 500);

                    setTimeout(() => {
                        this.map.invalidateSize()
                    }, 5000);

                })
                .catch((err) => {
                    this.blockedPanel = false;
                    console.log(err);
                });
        },
        reloadMap() {
            this.initMap();
            this.showLegend();
            this.showMarker();
        },
        // UPDATE
        back() {
            this.$router.replace({ name: "master-beat" })
        },
        updateItem() {
            this.loading = true;
            this.blockedPanel = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-beat/update',
                data: {
                    "beat_id": this.item.beat_id,
                    "beat_name": this.item.beat_name,
                    "outlet_ids": this.convertMultiOutlet(this.forms.outlet_ids),
                },
            })
                .then((res) => {
                    this.loading = false;
                    this.blockedPanel = false;

                    if (res) {
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Diupdate', life: 3000, });
                        this.$store.commit('setErrors', {});
                        this.back();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.loading = false;
                    this.blockedPanel = false;
                    this.$store.commit('setErrorEdit', err.response.data.data);
                    this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Diperbarui', life: 3000, });
                });
        },
        getItemById(id) {
            this.reloadForm = true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/master-beat/detail',
                params: {
                    "beat_id": id,
                }
            })
                .then(res => {
                    this.reloadForm = false;

                    if (res) {

                        this.$store.commit('setErrors', {});
                        this.item = res.data.data;
                        // this.filters.subdist_id = res.data.data.master_salesman.subdist_id;
                        // this.filters.kabupaten_id = res.data.data.kabupaten_id;
                        this.filters.provinsi_id = res.data.data.provinsi_id;
                        this.filters.salesman_id = res.data.data.salesman_id;
                        this.forms.outlet_ids = this.convertDataOutlet(res.data.data.salesman_beat_outlet);

                        setTimeout(() => {
                            this.searchDropdownKabupaten('');
                            // this.searchDropdownKecamatan('');
                            this.reloadMap();
                        }, 200);

                    }
                }, (this))
                .catch((err) => {
                    console.log(err);
                    this.reloadForm = false;
                });
        }
    },
}
</script>

<style>
.map {
    /* height: 510px; */
    height: 710px;
    width: 100%;
}

/*Legend specific*/
.legend {
    padding: 6px 8px;
    font: 14px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255, 255, 255, 0.8);
    /*box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);*/
    /*border-radius: 5px;*/
    line-height: 24px;
    color: #555;
}

.legend h4 {
    text-align: center;
    font-size: 16px;
    margin: 2px 12px 8px;
    color: #777;
}

.legend span {
    position: relative;
    bottom: 3px;
}

.legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin: 0 8px 0 0;
    opacity: 0.7;
}

.legend i.icon {
    background-size: 18px;
    background-color: rgba(255, 255, 255, 1);
}
.p-button {
    overflow: visible !important;
}
</style>

