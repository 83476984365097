<!-- eslint-disable vue/no-mutating-props -->
<template>
    <Dialog v-model:visible="editItemDialog" :style="{ width: '450px' }" header="Data Detail" :modal="true" class="p-fluid">
        <BlockUI :blocked="blockedPanel">
            <div v-if="item.outlet_status == 'y'">
                <div class="field">
                    <label for="outlet_name">Nama Outlet</label>
                    <InputText id="outlet_name" v-model="item.outlet_name" required="true" autofocus
                        :class="{ 'p-invalid': errorEdit.outlet_name }" />
                    <small class="p-invalid" style="color: red" v-if="errorEdit.outlet_name">{{ errorEdit.outlet_name[0]
                    }}</small>
                </div>
                <div class="field">
                    <label for="kabupaten_id">Kabupaten/Kota</label>
                    <Dropdown id="kabupaten_id" dataKey="kabupaten_id" ref="dkab" v-model="item.kabupaten_id"
                        :loading="loadingDropdownKabupaten" :options="dataDropdownKabupaten"
                        :class="{ 'p-invalid': errorEdit.kabupaten_id }" optionLabel="kabupaten_name" optionValue="kabupaten_id"
                        placeholder="Pilih Kabupaten" :filter="true" :showClear="true"
                        @filter="searchDropdownKabupaten($event, 'edit')" @change="changeKecamatan()" />
                    <small class="p-invalid" style="color: red" v-if="errorEdit.kabupaten_id">{{ errorEdit.kabupaten_id[0]
                    }}</small>
                </div>
                <div class="field">
                    <label for="kecamatan_id">Kecamatan</label>
                    <Dropdown id="kecamatan_id" dataKey="kecamatan_id" ref="dkecamatan" v-model="item.kecamatan_id"
                        :loading="loadingDropdownKecamatan" :options="dataDropdownKecamatan"
                        :class="{ 'p-invalid': errorEdit.kecamatan_id }" optionLabel="kecamatan_name" optionValue="kecamatan_id"
                        placeholder="Pilih Kecamatan" :filter="true" :showClear="true"
                        @filter="searchDropdownKecamatan($event, 'edit')" @change="changeKelurahan()" />
                    <small class="p-invalid" style="color: red" v-if="errorEdit.kecamatan_id">{{ errorEdit.kecamatan_id[0]
                    }}</small>
                </div>
                <div class="field">
                    <label for="kelurahan_id">Kelurahan</label>
                    <Dropdown id="kelurahan_id" dataKey="kelurahan_id" ref="dkelurahan" v-model="item.kelurahan_id"
                        :loading="loadingDropdownKelurahan" :options="dataDropdownKelurahan"
                        :class="{ 'p-invalid': errorEdit.kelurahan_id }" optionLabel="kelurahan_name" optionValue="kelurahan_id"
                        placeholder="Pilih Kelurahan" :filter="true" :showClear="true"
                        @filter="searchDropdownkelurahan($event, 'edit')" />
                    <small class="p-invalid" style="color: red" v-if="errorEdit.kelurahan_id">{{ errorEdit.kelurahan_id[0]
                    }}</small>
                </div>
            </div>
            <div v-else>
                <div class="flex align-items-center justify-content-center">
                    <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                    <span>Outlet <b>{{item.outlet_name}}</b> ini sudah nonaktif dan tidak dapat di edit</span>
                </div>
            </div>
        </BlockUI>
        <template #footer>
            <Button label="Batal" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
            <Button label="Simpan" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loading" v-if="item.outlet_status == 'y'"/>
        </template>
    </Dialog>
</template>


<script>

import { mapGetters } from "vuex";

export default {
    props: [
        "id",
        "outlet_data"
    ],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownKabupaten: false,
            loadingDropdownKecamatan: false,
            loadingDropdownKelurahan: false,
            blockedPanel: false,

            // dataDropdown
            dataDropdownKabupaten: null,
            dataDropdownKecamatan: null,
            dataDropdownKelurahan: null,

            // edit
            editItemDialog: false,

            // edit
            item: {},
            outlet_edit_data:[]
        }
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        editItemDialog: {
            handler() {
                setTimeout(() => {
                    if (this.editItemDialog == true) {
                        this.getItemById(this.id);
                    }
                }, 20);
            },
        }
    },
    computed: {
        ...mapGetters(['errorEdit']),
    },
    methods: {
        // DROPDOWN
        searchDropdownKabupaten(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dkab.filterValue = valueEdit;
                }

                if (purpose == "edit") {
                    this.loadingDropdownKabupaten = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/kota',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                        "provinsi_id": this.item.provinsi_id,
                    }
                })
                    .then(res => {

                        if (purpose == "edit") {
                            this.dataDropdownKabupaten = res.data.data;
                            this.loadingDropdownKabupaten = false;
                        } else if (purpose == null) {
                            this.dataDropdownKabupaten = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        changeKecamatan() {
            // eslint-disable-next-line vue/no-mutating-props
            this.item.kecamatan_id = null;
            this.dataDropdownKecamatan = null;
            this.$refs.dkecamatan.filterValue = null;
            this.searchDropdownKecamatan('');

            this.changeKelurahan();
        },
        searchDropdownKecamatan(event, purpose, valueEdit) {
            setTimeout(() => {
                if (this.item.kabupaten_id) {

                    if (valueEdit) {
                        this.$refs.dkecamatan.filterValue = valueEdit;
                    }

                    if (purpose == "edit") {
                        this.loadingDropdownKecamatan = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/kecamatan',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "provinsi_id": this.item.provinsi_id,
                            "kabupaten_id": this.item.kabupaten_id,
                        }
                    })
                        .then(res => {

                            if (purpose == "edit") {
                                this.dataDropdownKecamatan = res.data.data;
                                this.loadingDropdownKecamatan = false;
                            } else if (purpose == null) {
                                this.dataDropdownKecamatan = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });

                }
            }, 250);
        },
        changeKelurahan() {
            // eslint-disable-next-line vue/no-mutating-props
            this.item.kelurahan_id = null;
            this.dataDropdownKelurahan = null;
            this.$refs.dkelurahan.filterValue = null;
            this.searchDropdownKelurahan('');
        },
        searchDropdownKelurahan(event, purpose, valueEdit) {
            setTimeout(() => {
                if (this.item.kabupaten_id && this.item.kecamatan_id) {

                    if (valueEdit) {
                        this.$refs.dkelurahan.filterValue = valueEdit;
                    }

                    if (purpose == "edit") {
                        this.loadingDropdownKelurahan = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/kelurahan',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "provinsi_id": this.item.provinsi_id,
                            "kabupaten_id": this.item.kabupaten_id,
                            "kecamatan_id": this.item.kecamatan_id,
                        }
                    })
                        .then(res => {

                            if (purpose == "edit") {
                                this.dataDropdownKelurahan = res.data.data;
                                this.loadingDropdownKelurahan = false;
                            } else if (purpose == null) {
                                this.dataDropdownKelurahan = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });

                }
            }, 250);
        },
        // EDIT
        hideDialog() {
            this.editItemDialog = false;
        },
        updateItem() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-outlet/update',
                data: {
                    "outlet_id": this.item.outlet_id,
                    "outlet_name": this.item.outlet_name,
                    "kabupaten_id": this.item.kabupaten_id,
                    "kecamatan_id": this.item.kecamatan_id,
                    "kelurahan_id": this.item.kelurahan_id,
                },
            })
                .then((res) => {
                    this.loading = false;
                    if (res) {
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Diupdate', life: 3000, });
                        // this.$emit('submit');
                        this.$store.commit('setErrors', {});
                        this.$store.commit('setErrorEdit', {});
                        // this.item = {};
                        this.editItemDialog = false;
                        this.outlet_edit_data = [];
                        this.outlet_data.map((value) => {
                            if(value.id === this.id){
                               value.name = this.item.outlet_name;
                            }
                            this.outlet_edit_data.push(value);
                        });
                        console.log(this.outlet_edit_data);
                        this.$emit('submit', this.outlet_edit_data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.loading = false;
                    this.$store.commit('setErrorEdit', err.response.data.data);
                    this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Diperbarui', life: 3000, });
                });
        },
        getItemById(id) {
            this.blockedPanel = true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/master-outlet/detail',
                params: {
                    "outlet_id": id,
                }
            })
            .then(res => {
                if (res) {
                    this.$store.commit('setErrors', {});
                    this.item = res.data.data;

                    setTimeout(() => {
                        this.searchDropdownKabupaten('', 'edit', this.item.kabupaten_name);
                        this.searchDropdownKecamatan('', 'edit', this.item.kecamatan_name);
                        this.searchDropdownKelurahan('', 'edit', this.item.kelurahan_name);
                    }, 200);
                }

                this.blockedPanel = false;
            }, (this))
            .catch((err) => {
                console.log(err);
                this.blockedPanel = false;
            });
        }
    }
}
</script>