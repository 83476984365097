<!-- eslint-disable vue/no-mutating-props -->
<template>
    <Dialog v-model:visible="deactiveItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
        <BlockUI :blocked="blockedPanel">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="item == true">Apakah anda yakin akan menonaktifkan Outlet <b>{{name}}</b> ini ?</span>
            <span v-if="item == false">Outlet <b>{{name}}</b> ini sudah nonaktif</span>
        </div>
        </BlockUI>
        <template #footer>
            <Button label="Batal" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Simpan" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loading" v-if="item == true"/>
        </template>
    </Dialog>
</template>


<script>

import { mapGetters } from "vuex";

export default {
    props: [
		"id",
		"name",
    ],
    data() {
        return {
            // loading
            loading: false,
            blockedPanel: false,

            // edit
            deactiveItemDialog: false,

            // edit
            item: true,
        }
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        deactiveItemDialog: {
            handler() {
                setTimeout(() => {
                    if(this.deactiveItemDialog == true){
                        this.getItemById(this.id);
                    }
                }, 20);
            },
        }
    },
    computed:{
        ...mapGetters(['errorEdit']),
    },
    methods: {
        // EDIT
		hideDialog() {
			this.deactiveItemDialog = false;
		},
		updateItem() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-outlet/deactive',
                data: {
                    "outlet_id" : this.id,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil di Nonaktif', life: 3000,});
                    this.$emit('submit');
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    // this.item = {};
                    this.deactiveItemDialog = false;
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Diperbarui', life: 3000,});
            });
		},
        getItemById(id) {
            this.blockedPanel = true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/master-outlet/check-status',
                params: {
                    "outlet_id" : id,
                }
            })
            .then(res => {
                this.blockedPanel = false;

                if (res) {
                    this.$store.commit('setErrors', {});
                    this.item = res.data.data;
                }
            },(this))
            .catch((err) => {
                console.log(err);
                this.blockedPanel = false;
            });
        }
    }
}
</script>